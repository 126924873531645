@use './styles/'as *;

.booking__leave__container {
    width: 30vw;
}

.select__leave__type {
    background-color: $zumthor-color !important;
    color: black !important;

    &::after {
        color: black;
    }
}

.input__reason {
    border: none;
    background: $zumthor-color;
    margin-bottom: 1.5rem;
    border-radius: 10px;
    padding: 0.75rem;
    width: 100%;
    max-height: 100px;
    height: 100px;

    &::placeholder {
        color: #AAAAAA;
    }
}


.date__picker {
    background: $zumthor-color;
    border: none;
    padding: 4px 12px;
    border-radius: 10px;

}

.error__message {
    margin-top: 1rem;
    color: $sunset-orange-color;
    max-height: 2.5rem;
    max-lines: 2;
    height: 2.5rem;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box !important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: normal;
}