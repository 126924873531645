@use './styles/'as *;
// @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');
$ff-montserrat: 'Montserrat',
sans-serif;


// common style for app
$enable-caret: false;
$primary: $dodger-blue-color;
$secondary: $secondary-color;
$h1-font-size: $fs-30;
$h2-font-size: $fs-18;
$h3-font-size: $fs-16;
$h4-font-size: $fs-14;
$h5-font-size: $fs-12;
$h6-font-size: $fs-10;
$font-family-base: $ff-montserrat;
@import '~bootstrap/scss/bootstrap.scss';


* {
  padding: 0;
  margin: 0;
}

body {
  font-family: $ff-montserrat !important;
}

*:focus {
  outline: none;
}

li {
  text-decoration: none;
}

ul {
  list-style-type: none;
}

button {
  border: none;
  background: transparent;
}