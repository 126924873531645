@use 'styles/'as *;

.drawer__bar__container {
  background-color: $zumthor-color;
  @include border-radius(1rem);
  width: 100%;
  height: calc(100vh - 3rem);
  overflow: hidden;
  padding: 5px;
  @include animation(all 0.3s ease);
  z-index: 1000;
}

.menu-list-wp {
  padding-left: 3px;
  height: calc(100vh - 200px);
  overflow-x: hidden;
  overflow-y: scroll;
  @include animation(all 0.3s ease);
}

.logo {
  height: 50px;
  width: 50px;
  display: block;
  margin: 22px auto 50px auto;
  cursor: pointer;
}

.big-menu-icon {
  width: 25px;
  cursor: pointer;
  height: 25px;
  margin: 0 auto 30px auto;
  display: block;
}