// Mixins
@mixin transition($param...) {
  -ms-transition: $param;
  -o-transition: $param;
  -moz-transition: $param;
  -webkit-transition: $param;
  transition: $param;
}

@mixin transform($param...) {
  -ms-transform: $param;
  -o-transform: $param;
  -moz-transform: $param;
  -webkit-transform: $param;
  transform: $param;
}

@mixin border-radius($param) {
  -webkit-border-radius: $param;
  -moz-border-radius: $param;
  border-radius: $param;
}

@mixin box-shadow($param...) {
  -webkit-box-shadow: $param;
  -moz-box-shadow: $param;
  box-shadow: $param;
}

@mixin flex-column($param...) {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 $param;
  flex: 0 0 $param;
  max-width: $param;
}

@mixin flex-display() {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

@mixin animation($param...) {
  animation: $param;
  -webkit-animation: $param;
}

@mixin placeholder {
  &::-webkit-input-placeholder {
    @content;
  }

  &:-moz-placeholder {
    @content;
  }

  &::-moz-placeholder {
    @content;
  }

  &:-ms-input-placeholder {
    @content;
  }
}

@mixin flex($justify-content, $align-item) {
  display: flex;
  justify-content: $justify-content;
  align-items: $align-item;
}

@mixin background__blur {
  -webkit-filter: blur($backgroundModalBlur);
  -moz-filter: blur($backgroundModalBlur);
  -o-filter: blur($backgroundModalBlur);
  -ms-filter: blur($backgroundModalBlur);
  filter: blur($backgroundModalBlur);
  background: rgba(176, 171, 171, 0.05) !important;
  backdrop-filter: blur($backgroundModalBlur);
}
