@use './styles/' as *;

.box-layout {
  // display: flex;
  // flex-direction: column;
  padding: 1.25rem;
  @include border-radius(1rem);
  width: 100%;
  // flex-grow: 1;
  .header {
    min-height: 30px;
    &.have-body {
      margin-bottom: 1.25rem;
    }
  }
  .box-icon {
    width: 1.5rem;
    height: 1.5rem;
    display: flex;
    align-items: center;
    path {
      fill: $mine-shaft-color;
    }
  }
}
