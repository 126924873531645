@use 'styles/' as *;
.login-page {
  display: flex;
  flex-flow: row wrap;
  align-content: center;
  width: 100vw;
  height: 100vh;
  color: white;
}

.login-page__background {
  background-repeat: no-repeat;
  position: absolute;
  height: 100%;
  width: 100%;
  pointer-events: none;
  -webkit-filter: blur($backgroundModalBlur);
  -moz-filter: blur($backgroundModalBlur);
  -o-filter: blur($backgroundModalBlur);
  -ms-filter: blur($backgroundModalBlur);
  filter: blur($backgroundModalBlur);
  background-position: top;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.ILA-logo {
  width: 100px;
  height: 100px;
  pointer-events: none;
}

.login-page__content {
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 999;

  @media screen and (max-width: 425px) {
    width: 320px;
  }

  @media screen and (max-width: 375px) {
    width: 280px;
  }
}

.login-page__main-content {
  margin-top: 2.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1.7rem;
  backdrop-filter: blur(20px);
  border-radius: 1.25rem;
  margin-right: 1rem;
  margin-left: 1rem;
}

.login-page__main-content > * {
  margin-bottom: 2.5rem;
}

.login-page__main-content__top {
  font-weight: 100;
  font-size: $fs-40;
  text-align: center;
}

.login-page__main-content__mid {
  display: flex;
  flex-direction: column;
}

.login-page__main-content__btn {
  margin-bottom: 0 !important;
  color: #2b3674 !important;
  background: #ffffff !important;
  border-radius: 16px !important;
  width: 100%;
  padding: 13px 10px !important;
  height: fit-content !important;

  h3 {
    margin: 0;
    padding: 0;
  }

  img {
    display: inline-block;
    vertical-align: middle;
    width: 1.25rem;
    height: 1.25rem;
    margin-right: 0.625rem;
  }
}

.login-page__copyright {
  position: absolute;
  bottom: 3vh;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
