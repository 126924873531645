@import './variable.scss';

#root {
  @include transition(all 0.3s ease);
}

.no-border {
  border: none;
}

.ila-rounded {
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  border-radius: 16px;
}

.avatar {
  vertical-align: middle;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.title__large {
  font-weight: 600;
  font-size: $fs-30;
}

.title__medium {
  font-weight: 600;
  font-size: $fs-18;
}

.title__small {
  font-weight: 600;
  font-size: $fs-16;
}

.sub__tittle__large {
  font-weight: 500;
  font-size: $fs-18;
}

.sub__tittle__medium {
  font-weight: 500 !important;
  font-size: $fs-16 !important;
}

.sub__tittle__small {
  font-weight: 500;
  font-size: $fs-14;
}

.body__large {
  font-weight: 400;
  font-size: $fs-16;
}

.body__medium {
  font-weight: 400;
  font-size: $fs-14;
}

.body__small {
  font-weight: 400;
  font-size: $fs-12;
}

.body__very__small {
  font-weight: 400;
  font-size: $fs-10;
}

.capital__text {
  font-weight: 300;
  font-size: $fs-18;
}

/* width */
::-webkit-scrollbar {
  width: 3px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: rgba(55, 125, 255, 0.1019607843);
}

.color-1 {
  background-color: $zumthor-color !important;
}

.color-2 {
  background-color: #ffefe780 !important;
}

.color-3 {
  background-color: #fdebf980 !important;
}

.ila-ps-22 {
  padding-left: 22px !important;
}

.ila-pt-22 {
  padding-top: 22px !important;
}

.ila-pe-22 {
  padding-right: 22px !important;
}

.ila-pb-22 {
  padding-bottom: 22px !important;
}

.ila-ms-22 {
  margin-left: 22px !important;
}

.ila-mt-22 {
  margin-top: 22px !important;
}

.ila-me-22 {
  margin-right: 22px !important;
}

.ila-mb-22 {
  margin-bottom: 22px !important;
}

.ila-icon {
  margin-right: 20px;

  &:last-child {
    margin-right: 12px !important;
  }
}

.form-control,
.form-select {
  &:hover,
  &:focus,
  &:active {
    @include box-shadow(none);
    // border-color: $error-color;
  }
}

.btn-warning {
  background-color: $warning-color !important;
  border-color: $warning-color !important;
}

.btn {
  min-width: 130px !important;
  @include border-radius(8px !important);
}

.cursor-pointer {
  cursor: pointer;
}

.ila-form-content {
  display: flex;
  flex-direction: column;

  .edit-form {
    min-height: 40px;
    // margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }

    // padding: 0.375rem 0.75rem;
    // :not(.form-control),
    // :not(.form-select) {
    //   // padding: 0;
    // }
  }

  label,
  p {
    &:last-child {
      margin-bottom: 0;
    }
  }

  label {
    text-align: right;

    @media (max-width: 625px) {
      text-align: left;
    }
  }

  span {
    font-weight: 300;
  }

  &.experience-list {
    p {
      &:nth-child(1) {
        font-size: $fs-12;
        color: $secondary-color-grey;
      }

      &:nth-child(2) {
        font-size: $fs-14;
        color: $title;
      }

      &:nth-child(3) {
        font-size: $fs-12;
        color: $secondary-color-grey;
      }
    }
  }
}

@media (max-width: 425px) {
  .title__large {
    font-size: $fs-28;
  }

  .title__medium {
    font-size: $fs-16;
  }

  .title__small {
    font-size: $fs-14;
  }

  .sub__tittle__large {
    font-size: $fs-16;
  }

  .sub__tittle__medium {
    font-size: $fs-14 !important;
  }

  .sub__tittle__small {
    font-weight: 500;
    font-size: $fs-12;
  }

  .body__large {
    font-size: $fs-14;
  }

  .body__medium {
    font-size: $fs-12;
  }

  .body__small {
    font-size: $fs-10;
  }

  .body__very__small {
    font-size: $fs-8;
  }

  .capital__text {
    font-size: $fs-16;
  }
}
