@use './styles' as *;

@keyframes slide-in {
  from {
    transform: translateX(10rem);
  }

  to {
    transform: translateX(0rem);
  }
}

@keyframes slide-out {
  from {
    transform: translateX(0rem);
  }

  to {
    transform: translateX(40rem);
  }
}

.toast-list {
  display: flex;
  flex-direction: column;
  flex-flow: column;
  position: fixed;
  right: 1rem;
  top: 6rem;
  z-index: 3000;
}

.toast {
  font-weight: 300;
  display: flex;
  margin-bottom: 1rem;
  border-radius: 0.5rem;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.2);
  padding: 10px 15px;
  flex-grow: 1;
  animation: slide-in 300ms ease-out;
}

.toast__open {
  flex-grow: 1;
  display: flex !important;
}

.toast__close {
  flex-grow: 1;
  color: black;
  animation: slide-out 200ms ease-in;
}

.toast__left {
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
}

.toast__left__badge {
  margin-right: 1rem;
  max-width: 2.5rem;
  max-height: 2.5rem;
}

.toast__left__info {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.toast__left__info > * {
  max-width: 15rem;
}

.toast__left__info__title {
  font-weight: 300;
  font-size: $fs-16;
}

.toast__left__info__content {
  margin-bottom: 0;
  font-size: $fs-14;
}

.toast__right {
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  text-align: center;
  margin-left: 1rem;
}

.toast__right__icon {
  width: 1.1rem;
  margin-left: 0.5rem;
  cursor: pointer;
}

.toast__successful {
  background-color: $hint-of-green-color;
}

.toast__warning {
  background-color: $warning-background-color;
}

.toast__error {
  background-color: $error-background-color;
}

.toast__information {
  background-color: $information-background-color;
}

.toast__default {
  background-color: #fff;
}
