@use './styles/'as *;

.btn__filter {
    @include flex(center, center);
    flex-direction: column;
    margin-right: 0.5rem;
    height: 50px;
    padding: 0.25rem 0.5rem;

}

.btn__filter__selected {
    color: white;
    background-color: $dodger-blue-color;

    &:hover {
        background-color: $dodger-blue-color;
        color: white;
    }


    svg {
        path {
            fill: white;
        }
    }
}