@use 'styles/'as *;

.dashboard-box__left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 20rem;
  margin-right: 39px;
  text-align: center;

  img {
    margin-top: 10px;
    margin-bottom: 27px;
  }
}

.dashboard-box__right-title {
  margin-bottom: 33px;
}

.dashboard-box__content:nth-last-child(1) {
  margin-bottom: 0;
}

.toast_test {
  width: 7rem;
  height: 2rem;
  border: 1px solid #000;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem;
  border-radius: 1rem;
  cursor: pointer;
}

.toast_test:hover {
  background-color: black;
  color: white;
}

.toast_test {
  width: 7rem;
  height: 2rem;
  border: 1px solid #000;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem;
  border-radius: 1rem;
  cursor: pointer;
}

.toast_test:hover {
  background-color: black;
  color: white;
}

.left-side-bar-container {
  padding: 20px;
}

.page-header {
  width: 40%;
  color: $mine-shaft-color !important;
}

.text-profile {
  color: $mine-shaft-color !important;
}

.app-header {
  background-color: white !important;
  box-shadow: none !important;
  border-width: 0;
  padding-left: 20px;
}

.btn-main {
  font-size: $fs-16;
  color: $mine-shaft-color !important;
  padding: 20px !important;
}

.header-bar-username {
  padding: 10px;
}

.sticky-top {
  padding: 22px 22px 22px 22px !important;
}

.content-container {
  padding: 0 22px 0 22px !important;
  height: calc(100vh - 88px);
  overflow-x: hidden;
}

.ila-relative {
  position: relative;
}

.ila-btn-hamburger {
  width: 60px;
  height: 45px;
  background-color: transparent;
  border: 0;
  padding: 0;
  position: absolute;
  top: -5px;
  left: -50px;
  z-index: 10;
  // margin: 8px -15px 0 0;
  // display: block;
  @include transform(rotate(0deg) scale(0.4));

  // @include transition(all 0.3s ease);
  // cursor: pointer;
  // float: right;
  span {
    display: block;
    position: absolute;
    height: 9px;
    width: 100%;
    background: $mine-shaft-color;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    @include transform(rotate(0deg));
    @include transition(all 0.3s ease);

    &:nth-child(1) {
      top: 0px;
    }

    &:nth-child(2),
    &:nth-child(3) {
      top: 18px;
    }

    &:nth-child(4) {
      top: 36px;
    }
  }
}

@media screen and (min-width: 992px) {

  .ila-btn-hamburger,
  .logo-header {
    display: none;
  }
}

@media screen and (max-width: 991px) {
  .sticky-top {
    padding: 22px 44px 22px 44px !important;
  }

  .content-container {
    padding: 0 44px 0 44px !important;
  }

  .ila-mobile-menu {
    width: 0;
  }

  .ila-menu-active {
    .ila-btn-hamburger {
      span {
        background-color: $dodger-blue-color;

        &:nth-child(1) {
          top: 18px;
          width: 0%;
          left: 50%;
        }

        &:nth-child(2) {
          @include transform(rotate(45deg));
        }

        &:nth-child(3) {
          @include transform(rotate(-45deg));
        }

        &:nth-child(4) {
          top: 18px;
          width: 0%;
          left: 50%;
        }
      }
    }

    // .drawer__bar__container {
    //   @include transform(translateX(0));
    // }
  }

  // .drawer__bar__container {
  //   display: block;
  //   position: fixed;
  //   width: 50% !important;
  //   height: 100vh !important;
  //   top: 0;
  //   left: 0;
  //   z-index: 1030;
  //   // padding: 0 30px 30px;
  //   // overflow-y: auto;
  //   @include transform(translateX(-100%));
  //   @include transition(all 0.3s ease);
  // }
}