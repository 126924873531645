@use 'styles/' as *;

.container {
  // display: flex;
  // flex-direction: column;
  // align-items: center;

  button {
    border: none;
    width: 100px;
    height: 100px;
  }
}

.form-text-edit {
  padding: 0.375rem 0.75rem 0.375rem 0.75rem;
  margin-bottom: 0;
  line-height: 1.5;
  font-size: 0.875rem;
  text-align: left;
}

.ila-rounded {
  @include border-radius(1rem);
}

.profile-item {
  padding: 20px;
  // height: 100%;

  // background-color: red;
  .ila-profile-title {
    color: $title;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .editProfile-icon {
    cursor: pointer;
    width: 1.2rem;
    height: 1.2rem;
    // margin-bottom: 1rem;
  }

  .ila-profile-sub-title {
    color: $secondary-color-grey;
    margin-bottom: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .editProfile-icon {
      width: 1rem;
      height: 1rem;
      cursor: pointer;
    }
  }

  &.report-card {
    .avatar {
      margin-right: 5px;
    }
  }
}

.summary {
  // text-align: center;
  position: relative;

  .background {
    width: 100%;
    height: auto;
  }

  .summary__left {
    display: flex;
    align-items: center;

    .summary__left-avatar {
      position: relative;
      text-align: center;
    }
  }

  // .summary__left {
  //   display: flex;
  //   align-items: center;

  //   .summary__left-text {
  //     display: flex;
  //     flex-direction: column;
  //     text-align: start;
  //   }
  // }
}

.block-avatar {
  // margin-bottom: 50px;
  position: relative;
  .avatar {
    border: 3px solid #fff;
  }

  &:hover {
    .avatar-mask {
      z-index: 1;
    }
    .tooltip {
      opacity: 1;
    }
  }
  .avatar,
  .avatar-mask,
  .upload {
    max-width: 100%;
    width: 120px;
    height: 120px;
    color: #fff;
    cursor: pointer;
    @include border-radius(50%);
  }

  .avatar-mask,
  .upload {
    position: absolute;
    left: 50%;
    top: 50%;
    color: #fff;
    cursor: pointer;
    @include transform(translate(-50%, -50%));
  }

  .avatar-mask {
    width: 40px;
    height: 40px;
    z-index: -1;
    @include transition(all 0.3s ease);
    img {
      width: 2rem;
      height: 2rem;
    }
  }
  .upload {
    opacity: 0;
    z-index: 2;
  }
  .tooltip {
    top: -25px;
    @include transition(all 0.3s ease);
    &::after {
      right: unset;
      left: 50%;
      @include transform(translate(-50%, 0));
    }
  }
}

.right-info {
  // min-height: 470px;
  .text-item {
    max-width: 150px;
    width: 130px;
  }
}

.card-info {
  // padding: 0;
  // margin-top: 30px;
  // display: flex;
  // justify-content: space-around;

  .card-info-item {
    margin-bottom: 22px;
    // padding: 0;
  }

  // &.row {
  //   align-items: stretch;
  // }
}

.experience-list {
  list-style-type: none;

  li {
    margin: 1rem 0rem;
    border-left: 1px solid #000;
    padding-left: 0.5rem;
  }
}

@media screen and (max-width: 768px) {
  .left-info {
    text-align: center;
  }
}

.organization {
  margin: 15px;
}

// .rectangle {
//   position: relative;
//   // padding: 20px;
//   // box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);
// }
.ila-badge {
  margin-left: 10px;
  // width: 20px;
  // height: 20px;
  border-radius: 50%;
  background: $secondary-color;
  color: $error-background-color;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 6px;
  height: 30px;
  min-width: 30px;
}

.level-1-wrapper {
  position: relative;
  // display: grid;
  // grid-template-columns: repeat(2, 1fr);
  // grid-column-gap: 20px;
  width: 50%;

  // margin: 0 auto;
  .avatar {
    margin-right: 10px;
  }
}

p.ila-profile-des {
  margin-bottom: 10px;
}

.level-1 {
  margin-bottom: 20px;
  // background: green;
  // padding: 20px;
  display: flex;
  justify-content: start;
}

.level-2-wrapper {
  position: relative;
  width: 80%;
  margin-left: 45px;
  padding-left: 0;

  &::before {
    content: '';
    position: absolute;
    top: -20px;
    left: -20px;
    width: 2px;
    height: calc(100% - 8px);
    background: $gray-color;
  }

  li {
    margin-top: 20px;
  }
}

.level-2 {
  font-weight: normal;
  // background: pink;
  display: flex;
  justify-content: start;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0%;
    transform: translate(-100%, -50%);
    width: 20px;
    height: 2px;
    background: $gray-color;
  }
}

/* Department/ section colors */
// .department {
//   border-left: 4px solid red;
//   min-width: 400px;
//   // float: left;
//   // margin-left: 1.751313485113835%;
//   margin-bottom: 60px;
//   position: relative;
// }

// .department>a {
//   // margin: 0 0 -26px -4px;
//   z-index: 1;
// }

// .department>ul {
//   margin-top: 0px;
//   margin-bottom: 0px;
// }

// .department li {
//   padding-left: 25px;
//   // border-bottom: 4px solid red;
//   min-height: 80px;
//   position: relative;
// }
// .sections {
//   min-width: 400px;
//   position: relative;

//   .section {
//     margin-top: 20px;
//     margin-left: 20px;
//     min-height: 80px;
//     position: relative;

//     a {
//       background: #FDB0FD;
//       display: block;
//       overflow: hidden;
//       text-decoration: none;
//       padding: 20px;
//       // position: relative;

//       &::before {
//         content: "";
//         position: absolute;
//         top: 40px;
//         left: -20px;
//         width: 20px;
//         height: 2px;
//         background: blue;
//       }

//       &::after {
//         content: "";
//         position: absolute;
//         bottom: 50%;
//         left: -20px;
//         // transform: translate(-100%, -50%);
//         width: 2px;
//         // height: calc(100% - 10px);
//         height: 40px;
//         background: blue;
//       }
//     }
//   }

//   &.section-child {
//     padding-left: 25px;
//     position: relative;

//     .section {
//       a {
//         &::after {
//           height: 100px !important;
//           bottom: 20px !important;
//           top: unset;
//           background: green;
//         }
//       }
//     }
//   }
// }
