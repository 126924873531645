@use 'styles/' as *;

.input-error {
  border-color: $error-color !important;
}

.input-success {
  border-color: $success-color !important;
}

.ila-amination {
  @include transition(all 0.3s ease);
}

/*tooltip Box*/
.tooltip-container {
  position: relative;
  width: 38px;
  max-height: 38px;

  svg {
    width: 25px;
    height: auto;
  }

  &:hover {
    .tooltip {
      opacity: 1;
    }
  }
  /*tooltip for input type*/
  .tooltip {
    position: absolute;
    top: -45px;
    right: 0;
    font-size: 16px;
    text-align: center;
    background-color: $hint-of-green-color !important;
    @include transition(all 0.3s ease);
  }
}

.tooltip {
  width: max-content;
  @include border-radius(4px !important);
  p {
    padding: 5px;
  }
  /* tooltip  after*/
  &::after {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 12px 12.5px 0 12.5px;
    border-color: $hint-of-green-color transparent transparent transparent;
    position: absolute;
    top: 100%;
    right: 5px;
  }
  &.tooltip-success {
    background-color: $success-color !important;
    color: #fff;

    &::after {
      border-color: $success-color transparent transparent transparent;
    }
  }

  &.tooltip-error {
    background-color: $error-color !important;
    color: #fff;

    &::after {
      border-color: $error-color transparent transparent transparent;
    }
  }
}
