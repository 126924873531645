@use './styles'as *;

$bottomHeight: 38px;

.table-container {
  width: 100%;
  overflow-y: hidden;
  overflow-x: auto;
}

.table-responsive {
  height: calc(100% - $bottomHeight);
  overflow-y: auto;
  overflow-x: auto;

}

.table-responsive,
.table {
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  margin: 0 !important;
  padding: 0 !important;

  th {
    position: sticky;
    top: 0;
    z-index: 2;
    cursor: pointer;
    background: $zumthor-color !important;

    path {
      fill: $alto-color;
    }
  }
}

.selected__icon__color {

  path {
    fill: $mine-shaft-color !important
  }

}

tbody tr {
  height: 40px;

  &:hover {
    background-color: $periwinkle-color;
  }
}


tbody>tr:nth-child(2n) {
  background-color: $zumthor-color;
}

tbody>tr:nth-child(2n + 1) {
  background-color: $solid-white-color;
}

.table> :not(caption)>*>th {
  padding: 10px 16px !important;
  vertical-align: middle;
  font-size: 18px;
}

.table> :not(caption)>*>td {
  padding: 6px 16px !important;
  vertical-align: middle;
  font-size: 14px;
  width: 100%;
}

.table-link {
  text-decoration: none;
}


// table bottom
.table-footer {
  height: $bottomHeight;
  position: sticky;
  bottom: 0;
  @include flex(flex-end, center);
  background-color: $zumthor-color;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;


  select {
    margin-right: 1.875rem;
  }

  span {
    margin-right: 1.875rem;
    min-width: 80px
  }

  .btn__paging {
    border: none;
    background-color: transparent;
    align-items: center;
    padding: 0 1.25rem;
    justify-content: center;

  }
}

.enable__icon__color {
  path {
    fill: $mine-shaft-color;
  }
}

.disable__icon__color {
  path {
    fill: $alto-color;
  }
}