@use 'styles/'as *;

.popup__message__container {
  display: flex;
  width: 280px;
  flex-direction: column;
  align-items: center;
  padding: 0.75rem 1rem;
  border-radius: 1rem;

  button {
    width: 100%;
  }


}

.popup__icon {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 0;
  left: 50%;

  svg {
    width: 41px;
    height: 41px;
  }
}

.pop-up-message__successful__style {
  background-color: $hint-of-green-color;

  h2 {
    color: $fruit-salad-color;
  }

  .btn__action {
    background-color: $fruit-salad-color;
  }
}

.pop-up-message__information__style {
  background-color: $zumthor-color;

  h2 {
    color: $dodger-blue-color;
  }

  .btn__action {
    background-color: $dodger-blue-color;
  }
}

.pop-up-message__error__style {
  background-color: $pippin-color;

  h2 {
    color: $sunset-orange-color;
  }

  .btn__action {
    background-color: $sunset-orange-color;
  }
}

.pop-up-message__warning__style {
  background-color: $citrine-white-color;

  h2 {
    color: $pizazz-color;
  }

  .btn__action {
    background-color: $pizazz-color;
  }
}