@use './styles/' as *;

.offcanvas-backdrop,
.modal-backdrop {
  background-color: transparent;
  @include background__blur;
  opacity: 1 !important;
}

// .modal-open .container-fluid,
// .modal-open .container {
//   @include background__blur;
// }

.sidebar {
  position: absolute;
  height: 100%;
  width: 0;
  top: 0;
  right: 0;
  padding: 22px;
  background: rgba($color: black, $alpha: 0.05);
  @include transition(all 0.3s ease);
  @include transform(translateX(100%));
  z-index: 11111;
  &.left {
    @include transform(translateX(100%));
  }
  &.right {
    @include transform(translateX(0));
  }
}

.sidebar__container {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background: transparent;
  @include background__blur;
  z-index: 1030;
}

.modal-dialog {
  @include flex(center, center);
  width: auto !important;
}

.modal-content {
  width: auto !important;
  border-radius: 1rem !important;
  border: 0 !important;
}

.modal.fade .modal-dialog {
  @include transition(all 0.3s ease !important);
}

.offcanvas {
  border: 0 !important;
  background-color: rgba(0, 0, 0, 0.05) !important;
}
