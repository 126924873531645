@use 'styles/' as *;
// @font-face {
//   src: local('Pathway-Gothic-One'), url("../../assets/fonts/PathwayGothicOne-Regular.ttf") format("ttf");
//   font-family: 'Pathway-Gothic-One';
//   font-style: normal;
//   font-weight: 400;
// }
// @import url(https://fonts.googleapis.com/css?family=Pathway+Gothic+One);
body {
  margin: 0;
  // font-family: 'Pathway-Gothic-One';
}
.not-found-page {
  font-family: 'Pathway-Gothic-One';
  position: relative;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  background: -webkit-radial-gradient(
    center,
    ellipse cover,
    #ffffff 0%,
    #f2efd9 100%
  );
}

.svg-page {
  width: 40%;
  margin: 0 auto;
  max-height: 100vh;
  @media screen and (max-width: 1024px) {
    width: 100%;
  }
}
.font-mobile {
  font-size: $fs-20;
  @media screen and (max-width: 1024px) {
    font-size: $fs-18;
  }
  @media screen and (max-width: 768px) {
    font-size: $fs-16;
  }
}

@-webkit-keyframes rotate-right {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotate-right {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes rotate-left {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}
@keyframes rotate-left {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}
@-webkit-keyframes hover {
  0% {
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
  }
  50% {
    -webkit-transform: translateY(5%);
    transform: translateY(5%);
  }
  100% {
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
  }
}
@keyframes hover {
  0% {
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
  }
  50% {
    -webkit-transform: translateY(5%);
    transform: translateY(5%);
  }
  100% {
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
  }
}
@-webkit-keyframes pull {
  0% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
  }
  40% {
    -webkit-transform: scaleY(1.01);
    transform: scaleY(1.01);
  }
  60% {
    -webkit-transform: scaleY(0.99);
    transform: scaleY(0.99);
  }
  80% {
    -webkit-transform: scaleY(1.01);
    transform: scaleY(1.01);
  }
  100% {
    -webkit-transform: scaleY(0.99);
    transform: scaleY(0.99);
  }
  80% {
    -webkit-transform: scaleY(1.01);
    transform: scaleY(1.01);
  }
  100% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
  }
}
@keyframes pull {
  0% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
  }
  40% {
    -webkit-transform: scaleY(1.01);
    transform: scaleY(1.01);
  }
  60% {
    -webkit-transform: scaleY(0.99);
    transform: scaleY(0.99);
  }
  80% {
    -webkit-transform: scaleY(1.01);
    transform: scaleY(1.01);
  }
  100% {
    -webkit-transform: scaleY(0.99);
    transform: scaleY(0.99);
  }
  80% {
    -webkit-transform: scaleY(1.01);
    transform: scaleY(1.01);
  }
  100% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
  }
}
#airplane2,
#airplane1 {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-transform-origin: 200px 200px;
  transform-origin: 200px 200px;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-animation: rotate-right 60s linear 0s infinite;
  animation: rotate-right 60s linear 0s infinite;
}

#countryObjects {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-transform-origin: 200px 200px;
  transform-origin: 200px 200px;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-animation: rotate-right 240s linear 0s infinite;
  animation: rotate-right 240s linear 0s infinite;
}

#floatingGlobe {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-transform-origin: 200px 200px;
  transform-origin: 200px 200px;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-animation: rotate-left 360s linear 0s infinite;
  animation: rotate-left 360s linear 0s infinite;
}

#globe {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-animation: hover 0s linear 0s infinite;
  animation: hover 0s linear 0s infinite;
}

#windmill {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-transform-origin: 331px 201px;
  transform-origin: 331px 201px;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-animation: rotate-right 2s linear 0s infinite;
  animation: rotate-right 2s linear 0s infinite;
}

#cloud1 {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-animation: hover 3s linear 1s infinite;
  animation: hover 3s linear 1s infinite;
}

#cloud2 {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-animation: hover 3s linear 2s infinite;
  animation: hover 3s linear 2s infinite;
}

#cloud3 {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-animation: hover 3s linear 3s infinite;
  animation: hover 3s linear 3s infinite;
}

#circle1 {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-transform-origin: 200px 200px;
  transform-origin: 200px 200px;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-animation: rotate-right 12s linear 0s infinite;
  animation: rotate-right 12s linear 0s infinite;
}

#circle2 {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-transform-origin: 200px 200px;
  transform-origin: 200px 200px;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-animation: rotate-left 24s linear 0s infinite;
  animation: rotate-left 24s linear 0s infinite;
}

#circle3 {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-transform-origin: 200px 200px;
  transform-origin: 200px 200px;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-animation: rotate-right 12s linear 0s infinite;
  animation: rotate-right 12s linear 0s infinite;
}

#circle4 {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-transform-origin: 200px 200px;
  transform-origin: 200px 200px;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-animation: rotate-left 24s linear 0s infinite;
  animation: rotate-left 24s linear 0s infinite;
}

#circle5 {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-transform-origin: 200px 200px;
  transform-origin: 200px 200px;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-animation: rotate-right 12s linear 0s infinite;
  animation: rotate-right 12s linear 0s infinite;
}
