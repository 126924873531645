@use 'styles/'as *;


.notification__container {
    border-radius: 16px;
    justify-content: center;
    align-items: center;

    h6 {
        line-height: 20px;
    }

    path {
        fill: $sliver-charlie;
    }
}

.notification__title {
    @extend .sub__tittle__small;
    color: $secondary-color-grey;
}

.icon__mark-read {
    width: 0.75rem;
    height: 0.75rem;
    margin-right: 5px;

    path {
        fill: black;
    }

}