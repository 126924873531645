@use './styles/' as *;

@mixin button-interacts($color, $background: #fff) {
  &:hover,
  &:active,
  &:focus {
    color: $color;
    background-color: $background;
    // @include box-shadow(0px 2px 4px rgba(0, 0, 0, 0.25));
  }
}

@mixin button($color, $background) {
  color: $color;
  background-color: $background;
  @include button-interacts($color, $background);
}

@mixin button-outline($color, $background) {
  color: $background;
  border: 1px solid $background;
  @include button-interacts($color, $background);
}

.button__container {
  border: none;
  background: transparent;
  padding: 0 2rem;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
  color: $mine-shaft-color;
  @include transition(all 0.3s ease);
}

.button__normal {
  @include button($mine-shaft-color, $periwinkle-color);
}

.button__primary {
  @include button($solid-white-color, $dodger-blue-color);
}

.button__danger {
  @include button(#fff, $sunset-orange-color);
}

.button__disable {
  pointer-events: none;
  @include button(#fff, $gallery-color);
}

.button__outline__primary {
  @include button-outline($solid-white-color, $dodger-blue-color);
}

.button__outline__danger {
  @include button-outline($solid-white-color, $sunset-orange-color);
}
