@use 'styles/'as *;

.menu-icon {
  width: 24px;
  height: 24px;
}

.menu__item {
  //   height: 50px;
  border: none !important;
  background: transparent !important;
  margin-top: 20px;

  &:first-child {
    margin-top: 0;
  }

  cursor: pointer;

  .accordion-button {
    justify-content: space-between;
    min-height: 44px;
    padding: 10px !important;
    background: transparent !important;
    @include border-radius(8px !important);
    @include transition(all 0.3s easer);

    &:hover,
    &:focus,
    &:active {
      @include box-shadow(none);
      background: $periwinkle-color !important;
    }

    &::after {
      content: none !important;
    }

    path {
      fill: $mine-shaft-color;
    }

    .icon-toggle {
      @include animation(all 0.3s ease);
      position: absolute;
      right: 10px;
      top: auto;
      bottom: auto;
    }

    &:not(.collapsed) {
      .icon-toggle {
        @include transform(rotate(90deg));
      }
    }
  }

  &.ila-collapse {
    text-align: center;
    justify-content: center;

    .ila-menu-wp {
      width: 100%;
    }

    .menu-icon {
      margin-right: 0;
      margin-bottom: 5px;
    }

    .body__large {
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .body__large {
    // display: inline-block;
    // max-width: calc(100% - 24px);
    white-space: normal;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0 12px;
  }

  &.menu__item__select {
    .accordion-button {
      background: $dodger-blue-color !important;
      color: #fff !important;

      &:hover,
      &:focus,
      &:active {
        background: $dodger-blue-color !important;
      }

      .body__large {
        font-weight: 600;
      }
    }

    .menu-icon,
    .icon-toggle {
      path {
        fill: #fff;
      }
    }
  }
}