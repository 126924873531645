@use './styles/'as *;


.appvoalLeave__container {
    width: 100%;
    height: calc(100% - 24px);
}

.column__width {
    width: 14%;
}

.search__container {
    @include flex(flex-start, flex-end);
    flex-direction: column;
    width: 300px;
    padding: 0.5rem;
    background-color: $zumthor-color;
    margin-bottom: 1rem;
    border-radius: 8px;
    margin-left: auto;
    margin-right: 0;

    button {
        margin-left: 1rem;
    }

}

.btn_accept {
    background: $fruit-salad-color;
    padding: 0 0.5rem;
    border-radius: 8px;


    svg {
        path {
            fill: white;
        }
    }
}