@use './styles/'as *;


.input__container {
    position: relative;
    border-radius: 0.5rem;
    display: flex;
    padding: 0 0.5rem;
    width: 100%;
    background-color: white;
    align-items: center;
    justify-content: center;
}

.search__input__style {
    border: none;
    height: 32px;
    padding: 0 0.5rem;
    flex: 1;
}


.icon__search {
    width: 16px;
    height: 16px;
    margin-bottom: 3px;

    path {
        fill: $alto-color;
    }
}