@use 'styles/' as *;

.sub-menu-container {
  flex-direction: column;
  cursor: pointer;
  @include flex(center, flex-start);
  padding: 5px 0 5px 34px !important;
}

.sub-menu-item {
  // color: $sliver-charlie;
  width: 100%;
  min-height: 44px;
  padding: 5px 0;
  @include border-radius(8px !important);
  @include transition(all 0.3s ease);
  &:hover {
    background: $periwinkle-color !important;
    // color: $dodger-blue-color;
    // path {
    //   fill: $dodger-blue-color !important;
    // }
  }
  .icon-toggle {
    margin-right: 10px;
  }
  path {
    fill: $mine-shaft-color !important;
  }
}

.sub-menu-selected {
  color: $dodger-blue-color;
  .body__large {
    font-weight: 600 !important;
  }
  path {
    fill: $dodger-blue-color !important;
  }
}

.box-sub-menu {
  color: $mine-shaft-color;
  cursor: pointer;
  @include border-radius(8px !important);
  @include transition(all 0.3s ease);
  padding: 10px !important;
  margin: 10px !important;
  width: 170px;
  .menu-icon-box {
    width: 40px;
    height: 40px;
    path {
      fill: $mine-shaft-color;
    }
  }
  .text-box {
    width: 150px;
    height: 45px;
    white-space: pre-line;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &:active,
  &:hover {
    background: $periwinkle-color !important;
    // background: $dodger-blue-color !important;
    // color: #fff !important;
    // .menu-icon-box {
    //   path {
    //     fill: #fff !important;
    //   }
    // }
  }
}
