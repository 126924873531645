// color variables (old)
// CHANGED primary-color to dodger-blue-color
// CHANGED primary-text-color to mine-shaft-color

$secondary-color: #56ccf2;
$secondary-color-grey: #a3aed0;

$success-color: #4caf50;
$error-color: #ff5151;
$warning-color: #fb8c00;

$sliver-charlie: #b2b2b2;
$title: #2b3674;

$success-background-color: #eff6ee;
$warning-background-color: #fdf4e6;
$error-background-color: #faeded;
$information-background-color: #e8f6fc;
$gray-color: #8f8f8f;
$backgroundModalBlur: 4px;

// color variables (new)
$tory-blue-color: #153e9a;
$flirt-color: #960086;
$stiletto-color: #963030;
$brown-color: #965400;
$mine-shaft-color: #303030;
$boulder-color: #7d7d7d;
$foam-color: #fdf4fc;
$mercury-color: #e6e6e6;
$rock-blue-color: #a3aed0;
$athens-gray-color: #f0f0f3;
$solid-white-color: #ffffff;
$gallery-color: #efefef;
$alto-color: #d1d1d1;
$fruit-salad-color: #4caf50; // success
$hint-of-green-color: #e5ffe7; // success background
$pizazz-color: #fb8c00; // warning
$citrine-white-color: #fbf0e2; // warning background
$sunset-orange-color: #ff5151; // error
$pippin-color: #ffe5e5; // error background
$dodger-blue-color: #2367ff; // information
$zumthor-color: #e5edff; // information background
$periwinkle-color: #d4e1ff; // hover

// font size variable
$fs-8: 0.5rem;
$fs-10: 0.625rem;
$fs-11: 0.6875rem;
$fs-12: 0.75rem;
$fs-14: 0.875rem;
$fs-15: 0.9375rem;
$fs-16: 1rem;
$fs-18: 1.125rem;
$fs-20: 1.25rem;
$fs-22: 1.375rem;
$fs-23: 1.438rem;
$fs-24: 1.5rem;
$fs-25: 1.563rem;
$fs-26: 1.625rem;
$fs-28: 1.75rem;
$fs-30: 1.875rem;
$fs-32: 2rem;
$fs-33: 2.063rem;
$fs-34: 2.125rem;
$fs-35: 2.188rem;
$fs-36: 2.25rem;
$fs-38: 2.375rem;
$fs-40: 2.5rem;
